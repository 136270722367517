import React, { useRef, useEffect, useState } from "react";
// import _get from "lodash.get";

import _sampleSize from "lodash.samplesize";
import _sample from "lodash.sample";
import { useInterval } from "react-use";

import useChallengesData from "app/hooks/use-challenges-data";
import { useFeaturedChallengeIdx } from "app/hooks/use-featured-challenge";
import useWindowWidth from "app/hooks/use-window-width";

import { rotateFull } from "app/styles/animations";
import * as S from "app/components/polaroids.styles";

import CCard from "app/components/challenge-card";
import { ShuffleIcon } from "app/components/icons";

const { cardH, cardW, gridH } = S;

const overLapping = (bounds, point) =>
  point.left + cardW >= bounds.left && point.left <= bounds.right;

const gridPoints = ({
  height = cardH,
  width = cardW,
  overlap = 0.5,
  minPoints = 0,
  disallowed,
}) => {
  const maxH = gridH;
  const maxW = window.innerWidth;
  const cellH = height * overlap;
  const cellW = width * overlap;

  const columns = Math.ceil(maxH / cellH);
  const rows = Math.ceil(maxW / cellW);

  const gridHeight = columns * cellH;
  const gridW = rows * cellW;

  const ys = [...Array(columns).keys()].map((i) => i * cellH);
  const xs = [...Array(rows).keys()].map((i) => i * cellW);

  // Build coordinate point grid. Looping through each row.
  const grid = ys.reduce((rows, y) => {
    const row = xs.map((x) => ({
      left: x + Math.ceil((maxW - gridW) / 2),
      top: y + Math.ceil((maxH - gridHeight) / 2),
    }));

    return [...rows, ...row];
  }, []);

  if (disallowed && disallowed.current) {
    const bounds = disallowed.current.getBoundingClientRect();
    const valid = grid.filter((p) => !overLapping(bounds, p));
    const missing = minPoints - valid.length;

    if (missing < 1) {
      return valid;
    }

    const fillIn = [...Array(missing)].map((_, idx) => ({
      left:
        idx % 2 === 0
          ? bounds.left - cardW * 0.75 - 20
          : bounds.right - cardW * 0.25 + 20,
      top: ys[idx % ys.length],
    }));
    return [...valid, ...fillIn];
  }

  return grid;
};

const randomRotation = (deg) => Math.floor(Math.random() * (deg * 2) - deg);

// Build a coordinate grid of positions for the cards.
const useCardGrid = ({ disallowed, minPoints, gridW }) => {
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    setGrid(gridPoints({ disallowed, minPoints }));
  }, [gridW, minPoints, disallowed]);

  return grid;
};

// Assign a point to each card.
const usePlaceCards = ({ challenges, grid, active }) => {
  const [cardPoints, setCardPoints] = useState([]);

  useEffect(() => {
    setCardPoints(
      _sampleSize(grid, challenges.length).map((c) => ({
        ...c,
        angle: randomRotation(35),
      }))
    );
  }, [active, grid, challenges.length]);

  return { cardPoints };
};

const useShuffleCards = ({
  active,
  autoShuffle,
  paused,
  setActive,
  challenges,
}) => {
  const [spinning, setSpinning] = useState(false);

  const shuffle = () => {
    if (paused || spinning) {
      return;
    }
    setSpinning(true);
    setActive(
      challenges.indexOf(_sample(challenges.filter((_, idx) => idx !== active)))
    );
  };

  useInterval(shuffle, !!autoShuffle ? autoShuffle : null);

  return { shuffle, spinning, setSpinning };
};

const Polaroids = ({ challenges, autoShuffle, startOn }) => {
  const clearAreaRef = useRef(null);
  const width = useWindowWidth();
  const grid = useCardGrid({
    disallowed: clearAreaRef,
    gridW: width,
    minPoints: challenges.length,
  });
  const [paused, setPaused] = useState(false);
  const [active, setActive] = useState(startOn);

  const { cardPoints } = usePlaceCards({ challenges, grid, active });

  const { spinning, setSpinning, shuffle } = useShuffleCards({
    challenges,
    active,
    setActive,
    paused,
    autoShuffle,
  });

  return (
    <S.PBox>
      <S.PBoxOverlow>
        <div css={S.clearArea} ref={clearAreaRef} />
        {challenges.map((challenge, idx) => (
          <S.CardTile
            key={idx}
            active={idx === active}
            onClick={() => idx !== active && setActive(idx)}
            gridW={width}
            {...cardPoints[idx]}
          >
            <CCard
              {...challenge}
              allowFlip={idx === active}
              onFlip={(flipped) => setPaused(flipped)}
              allowHover={false}
              shadow={false}
              baseUri="/challenges"
            />
          </S.CardTile>
        ))}
      </S.PBoxOverlow>

      <S.Shuffle
        disabled={spinning}
        onClick={(e) => {
          e.preventDefault();
          shuffle();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.target.blur();
        }}
      >
        <ShuffleIcon
          css={spinning ? rotateFull : undefined}
          onAnimationEnd={() => setSpinning(false)}
        />
      </S.Shuffle>
    </S.PBox>
  );
};

Polaroids.defaultProps = {
  challenges: [],
  excluded: [],
  autoShuffle: 4000,
};

const PolaroidsWithData = () => {
  const challenges = useChallengesData();

  return (
    <Polaroids challenges={challenges} startOn={useFeaturedChallengeIdx()} />
  );
};

export default PolaroidsWithData;
