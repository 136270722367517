import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Home from "app/templates/home";

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "home" }) {
        ...HomePage
      }
    }
  `);

  return <Home {..._get(data, "contentJson")} />;
};

export default HomePage;
