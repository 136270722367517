import React, { memo, useState, useEffect } from "react";
import _get from "lodash.get";

import * as S from "./challenge-card.styles";
import Image from "./image";

export const CardFront = ({ sdg, title, flipped, setFlipped, cardImage }) => {
  const image = cardImage || _get(sdg, "cardImage");

  return (
    <S.CardFront active={!flipped} onClick={() => setFlipped(true)}>
      <S.CardBg color={_get(sdg, "color")} />
      {image && (
        <>
          <S.CardImgOverlay />
          <Image
            css={S.cardBgImg}
            image={image}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
        </>
      )}
      <S.CardTitleBox>
        <S.CardSubTitle>
          {!!_get(sdg, "sdg_id") && _get(sdg, "sdg_id") !== "0"
            ? _get(sdg, "sdg_id")
            : undefined}{" "}
          {_get(sdg, "sdg_name")}
        </S.CardSubTitle>
        <S.CardTitle>{title}</S.CardTitle>
      </S.CardTitleBox>
    </S.CardFront>
  );
};

const CardBack = ({
  sponsor,
  sdg,
  children,
  slug,
  Btn,
  flipped,
  setFlipped,
  baseUri = "/",
  viewDetailsLabel = "View Details",
  ...props
}) => (
  <S.CardBack active={flipped}>
    <S.CardInfo onClick={() => setFlipped(false)}>
      <S.CCHead>
        <S.CCHeadTxt>{_get(sponsor, "name")}</S.CCHeadTxt>
        {!!_get(sdg, "sdg_id") && _get(sdg, "sdg_id") !== "0" && (
          <S.CCHeadsdg>sdg {_get(sdg, "sdg_id")}</S.CCHeadsdg>
        )}
      </S.CCHead>
      {children}

      {Btn || (
        <S.ChallengeBtn url={`${baseUri}/${slug}`} label={viewDetailsLabel} />
      )}
    </S.CardInfo>
  </S.CardBack>
);

const ChallengeCard = ({
  children,
  className,
  allowFlip,
  allowHover,
  onFlip,
  shadowColor,
  shadow,
  ...props
}) => {
  const [flipped, setFlipped] = useState(false);
  const [hovering, setHover] = useState(false);

  const flipCard = (flipped) => {
    onFlip && onFlip(flipped);
    setFlipped(flipped);
  };

  useEffect(() => {
    if (allowFlip === false) {
      setFlipped(false);
    }
  }, [allowFlip]);

  return (
    <S.Card
      className={className}
      hoverFx={allowHover && (flipped || hovering)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      allowFlip={allowFlip}
    >
      {allowFlip && (
        <S.CardFace
          hoverFx={allowHover && (flipped || hovering)}
          flipped={flipped}
          shadowColor={shadowColor}
          shadow={shadow}
        >
          <CardFront {...props} flipped={flipped} setFlipped={flipCard} />
          <CardBack {...props} flipped={flipped} setFlipped={flipCard}>
            {children || (
              <S.CCTxtBox>
                <p css={S.challengeTxt}>{props.cardBack}</p>
              </S.CCTxtBox>
            )}
          </CardBack>
        </S.CardFace>
      )}
      {!allowFlip && (
        <CardFront {...props} flipped={false} setFlipped={() => null} />
      )}
    </S.Card>
  );
};

ChallengeCard.defaultProps = {
  allowFlip: true,
  allowHover: true,
  shadow: true,
};

export default memo(ChallengeCard);
