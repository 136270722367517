import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights } from "../styles/utils";
import { desktop } from "../styles/breakpoints";

import { heightMpx, heightpx } from "./header.styles";

export const BnrBox = styled.div`
  position: fixed;
  top: ${rem(heightMpx)};
  left: 0;
  right: 0;
  width: 100%;
  z-index: 500;
  padding: ${rem(17)};
  background: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
  text-align: center;

  @media (${desktop}) {
    top: ${rem(heightpx)};
  }
`;

export const BnrText = styled.p`
  margin: 0;
  padding: 0;
  ${fontSize(15, 15)};
  font-weight: ${fontWeights.book};
`;

export const brnLink = css`
  ${fontSize(12, 15)};
  margin-left: ${rem(15)};
`;
