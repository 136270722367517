import React from "react";
import useElHeight from "../hooks/use-el-height";

import Btn from "./btn";

import * as S from "./banner.styles";

const Banner = ({ enabled, text, cta }) => {
  const [height, ref] = useElHeight();

  if (!enabled) {
    return null;
  }

  return (
    <div style={{ height }}>
      <S.BnrBox ref={ref}>
        <S.BnrText>
          {text} <Btn css={S.brnLink} variant={"link"} {...cta} />
        </S.BnrText>
      </S.BnrBox>
    </div>
  );
};

export default Banner;

const enabledField = {
  label: "Enable",
  name: "enabled",
  widget: "boolean",
  default: false,
  required: false,
};

const textField = {
  label: "Text",
  name: "text",
  widget: "string",
};

const ctaField = {
  label: "CTA",
  name: "cta",
  widget: "object",
  fields: [
    {
      label: "Text",
      name: "label",
      widget: "string",
    },
    {
      label: "Link",
      name: "url",
      widget: "string",
    },
    {
      label: "New Window?",
      name: "new_window",
      widget: "boolean",
      default: false,
      required: false,
    },
  ],
};

export const homeBannerField = {
  label: "Homepage Banner",
  name: "home_banner",
  widget: "object",
  fields: [enabledField, textField, ctaField],
};

export const challengesBannerField = {
  label: "Challenge Detail Pages Banner",
  name: "challenges_banner",
  widget: "object",
  fields: [
    enabledField,
    {
      ...textField,
      hint: "%CHALLENGE_NAME% will be replaced with the name of the challenge",
    },
    ctaField,
  ],
};

export const challengeDetailBannerField = {
  label: "Challenge Detail Pages Banner",
  name: "finalists",
  widget: "object",
  fields: [
    enabledField,

    {
      label: "Title",
      name: "title",
      widget: "string",
      hint: "%CHALLENGE_NAME% will be replaced with the name of the challenge",
    },
    {
      ...textField,
      hint: "%CHALLENGE_NAME% will be replaced with the name of the challenge",
    },
  ],
};
