import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import useChallengesData from "app/hooks/use-challenges-data";

const FEATURED_CHALLENGE_QUERY = graphql`
  query {
    contentJson(template: { eq: "sponsors" }) {
      featuredChallenge {
        ...Challenge
      }
    }
  }
`;

const useFeaturedChallenge = () => {
  const data = useStaticQuery(FEATURED_CHALLENGE_QUERY);
  return _get(data, "contentJson.featuredChallenge") || {};
};

export const useFeaturedChallengeIdx = () => {
  const challenges = useChallengesData();
  const featuredChallenge = useFeaturedChallenge();

  return challenges.findIndex(
    (challenge) => _get(challenge, "id") === _get(featuredChallenge, "id")
  );
};

export default useFeaturedChallenge;
