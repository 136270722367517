import { useState } from "react";
import useWindowWidth from "../hooks/use-window-width";
import { useEffect, useRef } from "react";

export const useElHeight = () => {
  const vpWidth = useWindowWidth();
  const [height, setHeight] = useState(null);
  const ref = useRef();

  useEffect(() => {
    ref.current && setHeight(ref.current.clientHeight);
  }, [vpWidth]);

  return [height, ref];
};

export default useElHeight;
