import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Confetti from "react-dom-confetti";
import WinnerTile from "app/components/winner-tile";

import useChallengesData from "app/hooks/use-challenges-data";
import { useFeaturedChallengeIdx } from "app/hooks/use-featured-challenge";

import * as S from "app/components/winners.styles";

import Carousel from "app/components/carousel";

const PartyTime = ({ config }) => {
  const [partyTime, setPartyTime] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPartyTime(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [setPartyTime]);

  return (
    <S.ConfettiBox>
      <Confetti active={partyTime} config={config} />
    </S.ConfettiBox>
  );
};

Confetti.defaultProps = {
  config: {
    angle: 90,
    spread: "160",
    startVelocity: 45,
    elementCount: "90",
    dragFriction: 0.1,
    duration: "3120",
    stagger: "10",
    width: "11px",
    height: "14px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  },
};

const Winners = ({ enabled, title, confetti }) => {
  const challenges = useChallengesData();
  const initialSlide = useFeaturedChallengeIdx();

  if (!enabled) {
    return null;
  }

  return (
    <S.WinnerBox>
      <S.WinnerTitle>{title}</S.WinnerTitle>
      <Carousel
        Tile={<WinnerTile />}
        items={challenges}
        settings={{ initialSlide }}
      />
      <PartyTime config={confetti} />
    </S.WinnerBox>
  );
};

export default Winners;

export const winnersField = {
  label: "Show Winners",
  name: "showWinners",
  widget: "object",
  fields: [
    { label: "Enabled", name: "enabled", widget: "boolean", required: false },
    { label: "Title", name: "title", widget: "string", required: false },
  ],
};

export const winnersFragment = graphql`
  fragment WinnersBox on ContentJson {
    showWinners {
      enabled
      title
    }
  }
`;
