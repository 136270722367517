import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, colors } from "../styles/utils";

const { mint, black, white } = colors;

export const gridH = 500;
export const cardH = 360;
export const cardW = 300;

export const PBox = styled.div`
  position: relative;
  padding: ${rem(30)};
  height: ${rem(500)};
  background-color: ${mint};
`;

export const PBoxOverlow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const setPosition = ({ active, top = 0, left = 0, angle = 0 }) => {
  if (active) {
    return css`
      transform: translateX(50vw) translateX(${rem(cardW / -2)})
        translateY(250px) translateY(${rem(cardH / -2)}) translateZ(0)
        rotate(0deg);
      z-index: 20;
    `;
  }

  return css`
    transform: translateX(${rem(left)}) translateY(${rem(top)}) translateZ(0)
      rotate(${angle}deg);
    z-index: 1;
  `;
};

export const CardTile = styled.div`
  ${setPosition};
  position: absolute;
  width: ${rem(cardW)};
  height: ${rem(cardH)};
  transition: transform 0.6s ease-in-out;
  border-radius: ${rem(30)};
  overflow: hidden;
`;

export const clearArea = () => {
  // we could calculate the longest hypontenuse...
  // but this is close enough.
  const diameter = cardW;

  return css`
    position: absolute;
    width: ${diameter + 40}px;
    height: ${diameter + 40}px;
    top: 0;
    left: calc(50% - ${(diameter + 40) / 2}px);
    pointer-events: none;
  `;
};

// export const debug = css`
//   background: rgba(200, 0, 0, 0.2);
//   z-index: 10;
// `;

export const Shuffle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
  width: ${rem(60)};
  height: ${rem(60)};
  border: 0;
  border-radius: 100%;
  color: ${white};
  background-color: ${black};
  cursor: pointer;
  z-index: 20;
`;
