import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Banner from "app/components/banner";
import Btn, { btnField } from "app/components/btn";

import GetStarted, { getStartedField } from "app/components/get-started";
import Hero, { heroField } from "app/components/hero";
import Layout from "app/components/layout";
import Polaroids from "app/components/polaroids";
import SEOMeta, { seoField } from "app/components/seo";
import SponsorTile from "app/components/sponsor-tile";
import { StepHero, stepHeroField } from "app/components/steps";
import Winners, { winnersField } from "app/components/winners";

import useChallengesData from "app/hooks/use-challenges-data";
import { useFeaturedChallengeIdx } from "app/hooks/use-featured-challenge";

import SiteData from "app/content/_site-settings.json";

import Carousel from "app/components/carousel";

const Home = ({
  ctaBlock,
  heroIntro,
  howToCompete,
  heroVideo,
  showWinners,
  sponsors,
  ...props
}) => {
  const challenges = useChallengesData();
  const home_banner = _get(SiteData, "home_banner");
  const initialSlide = useFeaturedChallengeIdx();

  return (
    <Layout>
      <SEOMeta title={_get(props, "title")} {..._get(props, "seo")} />
      <article>
        <Banner {...home_banner} />
        <Winners {...showWinners} />
        {!_get(showWinners, "enabled") && <Polaroids />}
        <Hero {...heroIntro} hasMarkdown />
        <Hero {...heroVideo} isTall />
        <StepHero {...howToCompete} />
        <GetStarted {...ctaBlock} />
        <Carousel
          headline={_get(sponsors, "title", "Challenge Sponsors")}
          Tile={<SponsorTile />}
          items={challenges}
          Btn={
            <Btn
              center
              label="See all sponsors"
              to={"/sponsors"}
              {..._get(sponsors, "sponsors")}
            />
          }
          settings={{ initialSlide }}
        />
      </article>
    </Layout>
  );
};

export const homeFragment = graphql`
  fragment HomePage on ContentJson {
    ...SEO
    ...WinnersBox
    heroIntro {
      ...HeroFields
    }
    heroVideo {
      ...HeroFields
    }
    ...HowToCompete
    sponsors {
      title
      btn {
        ...ButtonFields
      }
    }
    ctaBlock {
      ...HeroFields
    }
  }
`;

export const homeFields = [
  seoField,
  winnersField,
  { ...heroField, name: "heroIntro" },
  { ...heroField, name: "heroVideo" },
  stepHeroField,
  {
    label: "Sponsors",
    name: "sponsors",
    widget: "object",
    fields: [
      {
        label: "Title",
        name: "title",
        widget: "string",
      },
      btnField,
    ],
  },
  { ...getStartedField, name: "ctaBlock", label: "CTA Module" },
];

export default Home;
